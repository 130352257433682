function createXHRObj(){
	let XHR;
	if(window.XMLHttpRequest){
		XHR = new XMLHttpRequest();
	}else{
		XHR = new ActiveXObject('Microsoft.XMLHTTP');
	}
	return XHR;
}

let XHR = createXHRObj();

function ajax(endpoint,method,args,callback){
	let result;
	let params = encodeURI(args);

	if(method === 'GET'){
		XHR.open(method,endpoint,true);
		XHR.onprogress = function(){
			console.log('recebendo informações do servidor...');
		}
		XHR.onload = function(){
			if(this.status == 200){

				if (callback && typeof callback === 'function') {
					callback(JSON.parse(this.responseText));
				}else{
					return JSON.parse(this.responseText);
				}
				//console.log(JSON.parse(this.responseText));

			}else{
				console.log('houve um problema com a resposta do servidor');
				if (callback && typeof callback === 'function') {
					callback(JSON.parse(this.responseText));
				}else{
					return JSON.parse(this.responseText);
				}
			}
		}
		XHR.send();

	}else if(method === 'POST'){
		XHR.open(method,endpoint,true);
		XHR.withCredentials = true;
		XHR.setRequestHeader('Content-type','application/x-www-form-urlencoded');
		XHR.onprogress = function(){
			console.log('recebendo informações do servidor...');
		}
		XHR.onload = function(){
			if(this.status == 200){

				if (callback && typeof callback === 'function') {
					callback(JSON.parse(this.responseText));
				}else{
					return JSON.parse(this.responseText);
				}
				//console.log(JSON.parse(this.responseText));
				
			}else{
				console.log('houve um problema com a resposta do servidor');
				if (callback && typeof callback === 'function') {
					callback(JSON.parse(this.responseText));
				}else{
					return JSON.parse(this.responseText);
				}
			}
		}
		XHR.send(params);
	}
}