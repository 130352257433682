//Dropdown
let dropdowns = document.querySelectorAll('ul[data-dropdown]');
dropdowns.forEach(ul => {
	//determina o input alvo
	let target = document.getElementById(ul.dataset.dropdowntarget);

	//determina o elemento do titulo
	let titulo;
	ul.childNodes.forEach(li => {
		if(li.dataset.dropdown === 'titulo')
			titulo = li;
	});

	//cria o evento de captura do valor
	ul.childNodes.forEach(li => {
		if (li.dataset.dropdown !== 'titulo' ){
			li.addEventListener('click', function(){
				titulo.innerHTML = li.innerHTML;
				ul.blur();
				if (li.value !== '' && li.value !== undefined){
					target.value = li.dataset.value;
				}
			})
		}
	});
});